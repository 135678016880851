import React, { useEffect, useMemo, useState } from "react";
import "./applyloans.css";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import axiosInstance from "../../../helpers/axiosInstance";
import AuthorizedContent from "../../../shared/AuthorizedContent";
import FlipLoader from "../../../shared/fliploader/FlipLoader";
import { ReactComponent as ArrowIcon } from "../../../assets/signin/right.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/signin/delete.svg";
import { ReactComponent as ProfileIcon } from "../../../assets/navbar/profile.svg";
import { ReactComponent as PendingIcon } from "../../../assets/pending.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/check.svg";
// import { ReactComponent as FilterIcon } from "../../../assets/filter.svg";
import { ReactComponent as PlusIcon } from "../../../assets/plus.svg";
import { filterLoanID } from "../../../helpers/filterLoanId";
import { AnimatePresence, motion } from "framer-motion";
import ReactSwitch from "react-switch";

function Applyloans(props) {
  const [applyLoanList, setApplyLoanList] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [amount, setAmount] = useState(5000);
  const [term, setTerm] = useState(3);
  const [interest, setInterest] = useState(0);
  const [comment, setComment] = useState(null);
  const [updateloading, setUpdateLoading] = useState(false);
  const [sanction_date, setSanctionDate] = useState(new Date());
  const [end_date, setEndDate] = useState(
    new Date(
      new Date(new Date().setDate(10)).setMonth(new Date().getMonth() + 3)
    )
  );
  const [loading, setLoading] = useState(true);
  // const [updateloading, setUpdateLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLoanStatusModal, setShowLoanStatusModal] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);
  // const [showFilterModal, setshowFilterModal] = useState(false);
  const { organisation, auth } = useAuth();

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialData = () => {
    // if (auth.user.role === "member" || props.parent === "details") {
    //   getTransactionDetails();
    // } else {
    getAllLoanFormsList();
    getAllMembersList();
    // }
  };

  const getAllLoanFormsList = () => {
    axiosInstance
      .get(`/api/applyloan/${organisation._id}/getloansform`)
      .then((response) => {
        setApplyLoanList(response.data.data);
        setLoading(false);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get loan forms. Try again!");
        console.log(error);
      });
  };

  const getAllMembersList = () => {
    axiosInstance
      .get(`/api/member/${organisation._id}/getallminimalmembers`)
      .then((response) => {
        setMembersList(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get members details. Try again!");
        console.log(error);
      });
  };

  const handleDelete = (id) => {
    setLoading(true);
    axiosInstance
      .delete(`/api/applyloan/${organisation._id}/deleteloanform/${id}`)
      .then((response) => {
        setLoading(false);
        toast("Loan application deleted successfully");
        getInitialData();
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to delete Loan application . Try again!");
        console.log(error);
      });
  };

  const handleApproveByAll = (id) => {
    setUpdateLoading(true);
    axiosInstance
      .post(`/api/applyloan/${organisation._id}/approveloanformall/admin/${id}`)
      .then((response) => {
        setUpdateLoading(false);
        toast("Loan application approved by all members successfully");
        setSelectedDetail(null);
        getInitialData();
        console.log(response.data);
      })
      .catch((error) => {
        setUpdateLoading(false);
        toast("Unable to approve the Loan application by all members . Try again!");
        console.log(error);
      });
  };

  // useMemo(() => {
  //   if (showLoanStatusModal) {
  //     axiosInstance
  //       .get(`/api/member/${organisation._id}/getallminimalmembers`)
  //       .then((response) => {
  //         setMembersList(false);
  //         // toast("Loan application deleted successfully");
  //         // getInitialData();
  //         console.log(response.data);
  //       })
  //       .catch((error) => {
  //         // setLoading(false);
  //         // toast("Unable to delete Loan application . Try again!");
  //         console.log(error);
  //       });
  //   }
  // }, [showLoanStatusModal]);

  const renderDetailModal = () => {
    let created_date = new Date(selectedDetail.created_date).toLocaleDateString(
      "en-us",
      {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }
    );
    let sanction_date = new Date(
      selectedDetail.sanction_date
    ).toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    let end_date = new Date(selectedDetail.end_date).toLocaleDateString(
      "en-us",
      { weekday: "long", year: "numeric", month: "long", day: "numeric" }
    );
    let approvedMembers = JSON.parse(selectedDetail.approved_members);
    let userApproved = false;
    approvedMembers.forEach((element) => {
      if (element === props.selectedMember._id) {
        userApproved = true;
      }
    });

    return (
      <section className="applyloan-detailmodal-container">
        <div className="applyloan-detailmodal-content">
          <header className="applyloan-detailmodal-header">
            <ArrowIcon
              className="applyloan-modal-lefticon"
              onClick={() => {
                setSelectedDetail(null);
                setShowLoanStatusModal(false);
              }}
            />
            Loan Details
          </header>
          <main className="applyloan-detailmodal-body">
            <div className="applyloan-detailmodal-form">
              <p className=" applyloan-listleft-user">
                <ProfileIcon /> {selectedDetail.member.first_name}{" "}
                {selectedDetail.member.last_name}{" "}
                <button
                  onClick={() =>
                    setShowLoanStatusModal((prevState) => !prevState)
                  }
                >
                  {showLoanStatusModal ? "Back to details" : "Loan Status"}
                </button>
              </p>
            </div>
            {showLoanStatusModal ? (
              <>
                <div className="applyloan-detailmodal-form applyloan-detailmodal-formlist applyloan-detailmodal-formlist-admin">
                
                  <ProfileIcon />
                  <label>Admin</label>
                  {selectedDetail.admin_approved ? (
                    <span className="applyloan-detailmodal-formlist-approved">
                      Approved
                    </span>
                  ) : (
                    <span className="applyloan-detailmodal-formlist-pending">
                      Pending
                    </span>
                  )}
                </div>
                <AuthorizedContent roles={["super-admin", "admin"]}>
              <div className="applyloan-detailmodal-form-switch">
                <label>Approve by all members</label>{" "}
                <ReactSwitch
                  onChange={()=>handleApproveByAll(selectedDetail._id)}
                  checked={selectedDetail.all_approved}
                  offColor={"#c20000"}
                  disabled={updateloading}
                />
              </div>
            </AuthorizedContent>
                <section className="applyloan-detailmodal-statussection">
                  {membersList?.map((member, idx) => {
                    if (member._id !== selectedDetail.member._id)
                      return (
                        <div
                          className="applyloan-detailmodal-form applyloan-detailmodal-formlist"
                          key={idx}
                        >
                          <ProfileIcon />
                          <label>{member.full_name}</label>
                          {JSON.parse(selectedDetail.approved_members).includes(
                            member._id
                          ) ? (
                            <span className="applyloan-detailmodal-formlist-approved">
                              Approved
                            </span>
                          ) : (
                            <span className="applyloan-detailmodal-formlist-pending">
                              Pending
                            </span>
                          )}
                        </div>
                      );
                  })}
                </section>
              </>
            ) : (
              <>
                <div className="applyloan-detailmodal-form">
                  <label>Loan Application Id</label>
                  <p>
                    {filterLoanID(
                      selectedDetail._id,
                      selectedDetail.created_date
                    )}
                  </p>
                </div>
                <div className="applyloan-detailmodal-form">
                  <label>Created Date</label>
                  <p>{created_date}</p>
                </div>
                <div className="applyloan-detailmodal-form">
                  <label>Amount</label>
                  <p>{selectedDetail.amount}</p>
                </div>

                <div className="applyloan-detailmodal-form">
                  <label>Term</label>
                  <p>{selectedDetail.term}</p>
                </div>
                <div className="applyloan-detailmodal-form">
                  <label>Interest</label>
                  <p>{selectedDetail.interest}</p>
                </div>
                {/* <div className="applyloan-detailmodal-form">
              <label>Pending_loan</label>
              <p>{selectedDetail.pending_amount}</p>
            </div> */}
                <div className="applyloan-detailmodal-form">
                  <label>Total Amount</label>
                  <p>{selectedDetail.total_amount}</p>
                </div>
                <div className="applyloan-detailmodal-form">
                  <label>Sanction Date</label>
                  <p>{sanction_date}</p>
                </div>
                <div className="applyloan-detailmodal-form">
                  <label>Loan Closing Date</label>
                  <p>{end_date}</p>
                </div>
                <div className="applyloan-detailmodal-form">
                  <label>Remarks</label>
                  <p>{selectedDetail.comment || "-"}</p>
                </div>
              </>
            )}
          </main>
          <footer className="applyloan-filter-footer">
            <AuthorizedContent roles={["super-admin", "admin"]}>
              <button
                className="applyloan-addbtn applyloan-filter-footerdelete"
                onClick={() => {
                  handleDelete(selectedDetail._id);
                  setSelectedDetail(null);
                }}
              >
                Delete
              </button>
              <button
                disabled={
                  !selectedDetail.all_approved || selectedDetail.admin_approved
                }
                className={
                  !selectedDetail.all_approved || selectedDetail.admin_approved
                    ? "applyloan-addbtn applyloan-filter-disabled applyloan-filter-footerapprove"
                    : "applyloan-addbtn applyloan-filter-footerapprove"
                }
                onClick={() => {
                  setLoading(true);
                  approveLoanByAdmin(selectedDetail._id);
                }}
              >
                Approve
              </button>
            </AuthorizedContent>

            <AuthorizedContent roles={["member", "secretary", "president"]}>
              {selectedDetail.member._id !== props.selectedMember._id ? (
                <>
                  <button
                    className="applyloan-addbtn applyloan-filter-footercancel"
                    onClick={() => {
                      setSelectedDetail(null);
                    }}
                  >
                    Cancel
                  </button>
                  {/* {userApproved ? ( */}
                  {/* <button
                    disabled={selectedDetail.all_approved}
                    className={
                      selectedDetail.all_approved || userApproved
                        ? "applyloan-addbtn applyloan-filter-disabled applyloan-filter-footerdelete"
                        : "applyloan-addbtn  applyloan-filter-footerdelete"
                    }
                    onClick={() => {
                      setSelectedDetail(null);
                      rejectLoan(selectedDetail._id);
                    }}
                  >
                    Reject
                  </button> */}
                  {/* ) : ( */}
                  <button
                    disabled={userApproved}
                    className={
                      selectedDetail.all_approved || userApproved
                        ? "applyloan-addbtn applyloan-filter-disabled applyloan-filter-footerapprove"
                        : "applyloan-addbtn applyloan-filter-footerapprove"
                    }
                    onClick={() => {
                      setLoading(true);
                      approveLoan(selectedDetail._id);
                    }}
                  >
                    Approve
                  </button>
                  {/* )} */}
                </>
              ) : (
                <>
                  <button
                    className="applyloan-addbtn applyloan-filter-footercancel"
                    onClick={() => {
                      setSelectedDetail(null);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="applyloan-addbtn applyloan-filter-footerdelete"
                    onClick={() => {
                      handleDelete(selectedDetail._id);
                      setSelectedDetail(null);
                    }}
                  >
                    Delete
                  </button>
                </>
              )}
            </AuthorizedContent>
          </footer>
        </div>
      </section>
    );
  };
  const checkRequiredFields = () => {
    if (new Date(sanction_date).getFullYear() >= new Date().getFullYear()) {
      if (
        new Date(sanction_date).getMonth() >= new Date().getMonth() ||
        new Date(sanction_date).getDate() >= new Date().getDate()
      ) {
        // if (new Date(sanction_date).getDate() >= new Date().getDate()) {
        if (
          typeof amount === "number" &&
          amount >= 5000 &&
          amount <= 30000 &&
          term >= 3 &&
          typeof term === "number" &&
          typeof interest === "number" &&
          sanction_date &&
          end_date
        ) {
          return true;
        } else {
          toast("Please fill all the required fields.");
          return false;
        }
        // } else {
        //   toast("Please fill all the required fields.1");

        //   toast("Please select future date!");
        //   return false;
        // }
      } else {
        toast("Please fill all the required fields.2");

        toast("Please select future date!");
        return false;
      }
    } else {
      toast("Please fill all the required fields.3");

      toast("Please select future date!");
      return false;
    }
  };

  const handleSubmit = () => {
    // console.log(checkRequiredFields());
    if (!checkRequiredFields()) {
    } else {
      setLoading(true);
      setShowModal(false);
      axiosInstance
        .post(
          `/api/applyloan/${organisation._id}/applyloanform/${props.selectedMember._id}`,
          {
            amount: parseInt(amount),
            created_date: Date.parse(new Date()),
            term,
            interest,
            comment,
            sanction_date: Date.parse(sanction_date),
            end_date: Date.parse(end_date),
          }
        )
        .then((response) => {
          toast("Member transaction added successfully");
          getInitialData();
          console.log(response.data);
        })
        .catch((error) => {
          setLoading(false);
          toast("Unable to add saving transaction details. Try again!");
          console.log(error);
        });
    }
  };

  const approveLoan = (id) => {
    axiosInstance
      .post(
        `/api/applyloan/${organisation._id}/approveloanform/${props.selectedMember._id}/loanform/${id}`
      )
      .then((response) => {
        // setLoading(false);
        setSelectedDetail(null);
        getInitialData();
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to add saving transaction details. Try again!");
        console.log(error);
      });
  };

  const rejectLoan = (id) => {
    axiosInstance
      .post(
        `/api/applyloan/${organisation._id}/rejectloanform/${props.selectedMember._id}/loanform/${id}`
      )
      .then((response) => {
        // setLoading(false);
        setSelectedDetail(null);
        getInitialData();
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to add saving transaction details. Try again!");
        console.log(error);
      });
  };

  const approveLoanByAdmin = (id) => {
    axiosInstance
      .post(
        `/api/applyloan/${organisation._id}/approveloanform/${
          props.selectedMember.role === "admin" ||
          props.selectedMember.role === "super-admin"
            ? selectedDetail.member._id
            : props.selectedMember._id
        }/admin/${id}`
      )
      .then((response) => {
        // setLoading(false);
        setSelectedDetail(null);
        getInitialData();
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to add saving transaction details. Try again!");
        console.log(error);
      });
  };

  useMemo(() => {
    if (amount !== 0) {
      setInterest((amount * term) / 100);
    }
  }, [amount, term]);

  // useMemo(() => {
  //   let newdate =new Date(sanction_date)

  //   setEndDate(
  //     new Date(
  //       new Date(new Date(newdate).setDate(10)).setMonth(new Date(newdate).getMonth() + term)
  //     )
  //   );
  // }, [term]);

  useMemo(() => {
    // if (amount !== 0) {
    let newdate = new Date(sanction_date);
    setEndDate(
      new Date(
        new Date(new Date(sanction_date).setDate(10)).setMonth(
          new Date(sanction_date).getMonth() + term
        )
      )
    );
    // }
  }, [sanction_date, term]);

  const renderModal = () => {
    let filteredend_date = new Date(end_date).toLocaleDateString("en-uk", {
      // weekday: "long",
      // day: "numeric",
      // year: "numeric",
      // month: "numeric",
    });
    // console.log(filteredend_date);
    return (
      <motion.section
        className="applyloan-modal-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.2 }}
      >
        <header className="applyloan-modal-header">
          <ArrowIcon
            className="applyloan-modal-lefticon"
            onClick={() => setShowModal(false)}
          />
          Apply Loan
        </header>
        <main className="applyloan-modal-body">
          <div className="applyloan-form">
            <label className="required"> Amount</label>
            <input
              type="text"
              value={amount || ""}
              minLength={5000}
              name="amount"
              onChange={({ target }) => {
                setAmount(parseInt(target.value));
              }}
              required={true}
            />
          </div>
          <div className="applyloan-form">
            <label className="required">Term (in months)</label>
            {/* <input
              type="text"
              name="term"
              onChange={({ target }) => {}}
              value={term}
              required={true}
            /> */}
            <select
              name="date"
              onChange={({ target }) => {
                console.log(target.value);
                setTerm(parseInt(target.value));
              }}
              value={term || ""}
              required={true}
            >
              {/* <option value={null}>Select </option> */}

              {[
                3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
                21, 22, 23, 24,
              ].map((element) => {
                return (
                  <option value={element} key={element}>
                    {" "}
                    {element}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="applyloan-form">
            <label className="required">Sanction Date</label>
            <input
              type="date"
              name="date"
              onChange={({ target }) => {
                setSanctionDate(target.value);
              }}
              value={sanction_date}
              required={true}
            />
          </div>
          <div className="applyloan-form">
            <label>Interest Amount</label>
            <input
              disabled={true}
              type="text"
              value={Math.round(interest) || 0}
              name="interest"
              onChange={({ target }) => {
                setInterest(parseInt(target.value));
              }}
              // required={true}
            />
          </div>
          <div className="applyloan-form">
            <label>End Date</label>
            <input
              disabled={true}
              type="type"
              name="date"
              onChange={({ target }) => {
                setEndDate(target.value);
              }}
              // placeholder={filteredend_date}
              value={filteredend_date}
              // required={true}
            />
          </div>
          <div className="applyloan-form">
            <label>Remarks</label>
            <input
              type="text"
              name="date"
              onChange={({ target }) => {
                setComment(target.value);
              }}
              // placeholder={end_date}
              value={comment || ""}
              // required={true}
            />
          </div>
        </main>
        <footer className="applyloan-modal-footer">
          {" "}
          <button className="applyloan-addbtn" onClick={handleSubmit}>
            Submit
          </button>
        </footer>
      </motion.section>
    );
  };

  // const rendrFilterModal = () => {
  //   return (
  //     <section className="applyloan-detailmodal-container">
  //       <div className="applyloan-detailmodal-content">
  //         <header className="applyloan-detailmodal-header">
  //           <ArrowIcon
  //             className="applyloan-modal-lefticon"
  //             onClick={() => {
  //               setshowFilterModal(false);
  //             }}
  //           />
  //           Filters
  //         </header>

  //         <main className="applyloan-detailmodal-body">
  //           {/* <AuthorizedContent roles={["super-admin", "admin"]}>
  //             <div className="applyloan-form">
  //               <label>Users</label>
  //               <select
  //                 value={filtermemberid}
  //                 onChange={({ target }) => {
  //                   setFiltermemberid(target.value);
  //                 }}
  //               >
  //                 <option value={"all"}>All </option>
  //                 {membersList.map((member) => {
  //                   return (
  //                     <option value={member._id} key={member._id}>
  //                       {" "}
  //                       {member.full_name}{" "}
  //                     </option>
  //                   );
  //                 })}
  //               </select>
  //             </div>
  //           </AuthorizedContent> */}
  //           <div className="applyloan-form">
  //             <label>Status</label>
  //             <select
  //               value={filteractive}
  //               onChange={({ target }) => {
  //                 setFilteractive(target.value);
  //               }}
  //             >
  //               <option value={"all"}>All</option>
  //               <option value={true}>Active</option>
  //               <option value={false}>Closed</option>
  //             </select>
  //           </div>
  //         </main>
  //         <footer className="applyloan-filter-footer">
  //           <button
  //             className="applyloan-addbtn applyloan-filter-footerbtn"
  //             onClick={() => {
  //               setFiltermemberid("all");
  //               setFilteractive("all");
  //             }}
  //           >
  //             Clear
  //           </button>
  //           <button
  //             className="applyloan-addbtn applyloan-filter-footerbtn"
  //             onClick={() => {
  //               setLoading(true);
  //               getInitialData();
  //               setshowFilterModal(false);
  //             }}
  //           >
  //             Apply
  //           </button>
  //         </footer>
  //       </div>
  //     </section>
  //   );
  // };

  return (
    <div className="applyloan-container">
      <AnimatePresence>{showModal ? renderModal() : null}</AnimatePresence>
      {selectedDetail ? renderDetailModal() : null}
      {/* {showFilterModal ? rendrFilterModal() : null} */}
      <div className="applyloan-header">
        {props.parent !== "details" ? (
          <h3 className="applyloan-heading">Loan Forms</h3>
        ) : null}
        {/* <button
          className="applyloan-filterbtn"
          onClick={() => setshowFilterModal(true)}
        >
          <FilterIcon />
        </button> */}
        <AuthorizedContent roles={["member", "secretary"]}>
          <button
            className="applyloan-addiconbtn"
            onClick={() => setShowModal(true)}
          >
            <PlusIcon />
          </button>
        </AuthorizedContent>
      </div>
      <div className="applyloan-wrapper">
        {loading ? (
          <div className="applyloan-loader">
            <FlipLoader />
          </div>
        ) : !applyLoanList?.length ? (
          <p>There are no loan forms.</p>
        ) : (
          <div className="applyloan-listwrapper">
            {/* <motion.header
              className="applyloan-listwrapper-header"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.1 }}
            >
              <label className="applyloan-listwrapper-headerleft">
                <label>
                  Active : <span>{transactionMetdata.active_loan}</span>
                </label>{" "}
                <label>
                  Closed : <span>{transactionMetdata.closed_loan}</span>
                </label>
              </label>
              <label className="applyloan-listwrapper-headerright">
                Total Loan Amount :{" "}
                <span>₹ {transactionMetdata.total_loan}</span>{" "}
              </label>
            </motion.header> */}
            {applyLoanList?.map((transaction, idx) => {
              let date = new Date(transaction.created_date).toLocaleDateString(
                "en-us",
                {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              );
              return (
                <motion.div
                  className={
                    transaction.status === 0
                      ? "applyloan-list applyloan-list-pending"
                      : transaction.status === 1
                      ? "applyloan-list applyloan-list-adminpending"
                      : "applyloan-list"
                  }
                  key={idx}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="applyloan-listleft">
                    <p className="applyloan-listleft-date">{date}</p>
                    {/* {props.parent !== "details" &&
                    auth.user.role !== "member" ? ( */}
                    <p className=" applyloan-listleft-user">
                      <ProfileIcon /> {transaction.member.first_name}{" "}
                      {transaction.member.last_name}
                    </p>
                    {/* // ) : null} */}
                    <p
                      className="applyloan-listleft-id"
                      onClick={() => {
                        setSelectedDetail(transaction);
                        // setActive(transaction.active);
                      }}
                    >
                      {filterLoanID(transaction._id, transaction.created_date)}{" "}
                      <span
                        className={
                          (transaction.status === 0) |
                          (transaction.status === 1)
                            ? "applyloan-listleft-status  applyloan-listleft-status-pending"
                            : "applyloan-listleft-status applyloan-listleft-status-checked"
                        }
                      >
                        {transaction.status === 0 ||
                        transaction.status === 1 ? (
                          <PendingIcon />
                        ) : (
                          <CheckedIcon />
                        )}
                        <span>
                          {transaction.status === 0
                            ? "Pending"
                            : transaction.status === 1
                            ? "Admin Pending"
                            : "Approved"}
                        </span>
                      </span>{" "}
                    </p>
                  </div>
                  <label
                    className={
                      auth.user.role === "super-admin" ||
                      auth.user.role === "admin"
                        ? "applyloan-listrighttotal"
                        : "applyloan-listrighttotal applyloan-listrightmember"
                    }
                  >
                    ₹ {transaction.total_amount}
                  </label>

                  <AuthorizedContent roles={["super-admin", "admin"]}>
                    <div
                      className="applyloan-listrightdelete"
                      onClick={() => handleDelete(transaction._id)}
                    >
                      <DeleteIcon />
                    </div>
                  </AuthorizedContent>
                </motion.div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default Applyloans;
